import Footer from 'components/UI/Footer/Footer';
import AppRoutes from 'config/routes/AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

function App() {
  return (
    <>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
    <ToastContainer />
    <Footer />
    </>
  );
}

export default App;
