import React, { memo, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { INDEX_PATH, NOT_FOUND_PAGE_PATH } from './paths';

const IndexPage = React.lazy(() => import('pages/IndexPage'));
const NotFoundPage = React.lazy(() => import('pages/NotFoundPage'));

const AppRoutes: React.FC = () => {

    const element = useRoutes([
        { path: `${INDEX_PATH}`, element: <Suspense fallback={<div className='suspense-loader'></div>}><IndexPage /></Suspense> },
        { path: NOT_FOUND_PAGE_PATH, element: <NotFoundPage /> },
        { path: '/', element: <Navigate replace to={INDEX_PATH} /> },
        { path: '*', element: <Navigate replace to={NOT_FOUND_PAGE_PATH} /> }
    ]);
    return element;
}

export default memo(AppRoutes);